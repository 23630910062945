import userCan from '@/mixins/UserCan'

export default [
  ((userCan('dashboard-access')) && {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  }),
  ((userCan('actions-access') || userCan('permissions-access') || userCan('roles-access')) && {
    header: 'Permissions & Roles',
  }),
  ((userCan('actions-access') || userCan('permissions-access') || userCan('roles-access')) && {
    title: 'Permissions',
    icon: 'ShieldIcon',
    children: [
      (userCan('actions-access') && {
        title: 'Actions',
        route: 'actions',
      }),
      (userCan('permissions-access') && {
        title: 'Permissions',
        route: 'permissions',
      }),
      (userCan('roles-access') && {
        title: 'Roles',
        route: 'roles',
      }),
    ].filter(Boolean),
  }),
  ((userCan('actors-access') || userCan('users-access')) && {
    title: 'Users Settings',
    icon: 'UsersIcon',
    children: [
      (userCan('users-access') && {
        title: 'Users',
        route: 'users',
      }),
      (userCan('actors-access') && {
        title: 'Actors',
        route: 'actors',
      }),
    ].filter(Boolean),
  }),

  (userCan('contact_us-access') && {
    header: 'Contact Us',
  }),
  (userCan('contact_us-access') && {
    title: 'Messages',
    icon: 'FileTextIcon',
    children: [
      (userCan('contact_us-access') && {
        title: 'Contact Us',
        route: 'contact-us',
      }),
    ].filter(Boolean),
  }),

  ((userCan('awards-access') ||
    userCan('application_individual-access') ||
    userCan('application_team-access') ||
    userCan('comity_members-access') ||
    userCan('comity-access') || userCan('applications_management-access')) && {
    header: 'Awards and Candidates',
  }),
  ((userCan('awards-access') ||
    userCan('application_individual-access') ||
    userCan('application_team-access') || userCan('comity_members-access') ||
    userCan('comity-access') || userCan('applications_management-access')) && {
    title: 'Awards',
    icon: 'AwardIcon',
    children: [
      (userCan('awards-access') &&
      {
        title: 'awards',
        route: 'awards',
      }),
      ((userCan('application_individual-access') || userCan('application_team-access')) &&
      {
        title: 'applications',
        route: 'applications',
      }),
      (userCan('kpi-access') &&
      {
        title: 'kpi',
        route: 'kpi',
      }),
      (userCan('comity-access') &&
      {
        title: 'comity',
        route: 'comity',
      }),
      (userCan('judges-access') &&
      {
        title: 'judges',
        route: 'judges',
      }),
      (userCan('applications_management-access') &&
      {
        title: 'applications management',
        href: `${process.env.VUE_APP_APPLICATIONS_MANAGEMENT_URL}?token=${window.localStorage.getItem("token")}`,
      
      }),
    ].filter(Boolean),
  }),

  ((userCan('gallery-access') || userCan('sliders-access')) &&
  {
    header: 'Gallery And Slider',
  }),
  ((userCan('gallery-access') || userCan('sliders-access')) &&
  {
    title: 'gallery & slider',
    icon: 'ImageIcon',
    children: [
      (userCan('gallery-access') &&
      {
        title: 'gallery',
        route: 'gallery',
      }),
      (userCan('sliders-access') &&
      {
        title: 'slider',
        route: 'slider',
      }),
      (userCan('videos-access') &&
      {
        title: 'videos',
        route: 'videos',
      })
    ].filter(Boolean),
  }),
  ((userCan('post-access') || userCan('news-access')) && {
    header: 'Posts & Pages',
  }),
  ((userCan('news-access') || userCan('news_tag-access') || userCan('news_category-access'))
    && {
    title: 'News',
    icon: 'FileIcon',
    children: [
      (userCan('news-access') && {
        title: 'News',
        route: 'news',
      }),
      (userCan('news_tag-access') &&
      {
        title: 'news Tags',
        route: 'news-tag',
      }),
      (userCan('news_category-access') && {
        title: 'news Category',
        route: 'news-category',
      }),
    ].filter(Boolean),
  }),
  (userCan('post-access') && {
    title: 'Pages',
    icon: 'TabletIcon',
    children: [
      (userCan('post-access') && {
        title: 'All Pages',
        route: 'pages',
      }),
    ].filter(Boolean),
  }),
  (userCan('general_settings-access') && {
    header: 'Settings',
  }),
  (userCan('general_settings-access') && {
    title: 'settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'settings',
        route: 'settings',
      },
      (userCan('rejection_reasons-access') && {
        title: 'Rejection Reasons',
        route: 'rejection-reasons',
      }),
    ].filter(Boolean)
  }),
].filter(Boolean)
